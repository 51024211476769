import React from 'react'
import {Link, NavLink} from 'react-router-dom'
import {SiShopware} from 'react-icons/si'
import {MdOutlineCancel} from 'react-icons/md'
import { TooltipComponent } from '@syncfusion/ej2-react-popups'

import {links} from '../data/dummy'
import { useStateContext, /*setActiveMenu*/ } from '../contexts/ContextProvider'

const Sidebar = () => {
  const{ currentColor, setActiveMenu, screenSize } = useStateContext();
  const handleCloseSideBar = () => {
    if( activeMenu !== undefined && screenSize < 900){
      setActiveMenu(false)
    }
  }

  const activeMenu = true;
  const activeLink ='flex items-center gap-5 pl-4 pt-3 pd-2.5 rounded-lg text-black text-md m-2';
  const normalLink ='flex items-center gap-5 pl-4 pt-3 pd-2.5 rounded-lg text-gray-700 dark:text-gray-200 dark:hiver:text-black hover:bg0light0gray m-2';

  return (
    <div className="ml-3 h-screen md:overflow-hidden overflow-auto md:hover:overflow-auto pb-10">
      {activeMenu && (
      <>
      <div className="flex justify-between items-center">
        <Link to="/" onClick={handleCloseSideBar}
        className="items-center gap-3 ml-3 mt-4 flex text-xl font-extrabold tracking-tight dark:text-white text-slate-900">
          <SiShopware /><span> Shoppy </span>
        </Link>
        <TooltipComponent content="Menu" position="BottomCenter">   
         <button 
         type="button" onClick= {()=> setActiveMenu(prevActiveMenu => !prevActiveMenu)}
         style={{color: currentColor}}
        className="text-xl rounded-full p-3 hover:bg-light-gray mt-4 block"
        >
        <MdOutlineCancel color='black' size={30}/>
        </button>
        </TooltipComponent>
        </div>
        <div className='mt-10'>
        {links.map((item)=>{
          console.log(JSON.stringify(item, null, 2))
          return (
            <div key={item.title}>
              <p className="text-gray-400 m-3 mt-4 uppercase">
                {item.title}
              </p>
              {item.links.map((link) => (
                <NavLink
                  to={`/${link.name}`}
                  key={link.name}
                  onClick={handleCloseSideBar}
                  className={({ isActive }) => isActive ? activeLink : normalLink}
                >
                  {link.icon}
                  <span className="capitalize">{link.name}</span>
                </NavLink>
              ))}
            </div>
          )
        })}
        </div>
        </>)}
    </div>
  )
}

export default Sidebar